import React from 'react';
import { DatePicker, Form, Select } from 'antd';
import { times } from '../utils';
import moment from 'moment';

const DateTimePicker = ({ form, name, label, value, submitting, }) => {

    React.useEffect(() => {
        if (value) {
            form.setFieldsValue({
                [name]: {
                    date: moment(value),
                    time: moment(value).format('HH:mm'),
                }
            });
        } else {
            form.setFieldsValue({
                [name]: {
                    date: moment(),
                    time: null,
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-6 col-lg-6">
                    <Form.Item name={[name, 'date']} label={label && `${label} date`} rules={[{ required: true, message: "Please input the date!", },]}>
                        <DatePicker format="LL" type="text" size="large" autoComplete="off" disabled={submitting} style={{ width: '100%' }} />
                    </Form.Item>
                </div>
                <div className="col-6 col-lg-6">
                    <Form.Item name={[name, 'time']} label={label && `${label} time`} rules={[{ required: true, message: "Please input the time!", },]}>
                        <Select autoComplete="off" size="large" placeholder="--:--" disabled={submitting}>
                            {times.map(time => (
                                <Select.Option key={time} value={time}>{time}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        </React.Fragment>
    );
}


export default DateTimePicker;