import React, { useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { countries } from 'countries-list';
import { axius, func } from './utils';
import { Loading } from './components';

import Main from './Main';
import Country from './Country';
import Authenticate from './screens/Authenticate';

// ::: store
import * as authAct from './store/auth/_authActions';
import * as utilsAct from './store/data/_dataActions';

const App = (props) => {
  const { _auth: { authenticated, logg, token }, } = props;

  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(true);


  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      axius.get(`users/${logg.uuid}`),
      axius.get(`settings`),
      axius.get(`settings/headers`),
      axius.get(`settings/countries`),
      axius.get(`whitelabels/${func.config.whiteLabel}`),
    ]).then(res => {
      if (res[0].status === 200) {
        props.signInSuccess(token, res[0].data);
      } else {
        logg.uuid && props.signOutSuccess();
      }
      res[1].status === 200 && props.setSetSettings('settings', res[1].data);
      res[3].status === 200 && props.setSetSettings('countries', res[3].data);
      res[4].status === 200 && props.setSetSettings('whiteLabel', res[4].data);
      checkCountryAvailable(res[2].status === 200 ? (res[2]?.data['cf-ipcountry'] || 'gh') : 'gh');
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCountryAvailable = (code) => {
    setLoading(false);
    let geo = (func.getStorageJson('countries') || []).find(cnt => cnt.code.toLowerCase() === code.toLowerCase()) || {};
    if (geo.code) {
      setAvailable(true);
      func.setStorageJson('geo', geo);
    } else {
      setAvailable(false);
      geo = countries[code.toUpperCase()] || {};
      geo['code'] = code;
      func.setStorageJson('geo', geo);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (!available && !loading) {
    return <Country />;
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loading />}>
        <Router>
          {authenticated ? (<Route render={() => <Main />} />) : (<Authenticate />)}
        </Router>
      </React.Suspense>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  _auth: state.auth,
  _data: state.data,
  _utils: state.utils,
});

const mapDispatchToProps = (dispatch) => ({
  signInSuccess: (token, data) => {
    dispatch(authAct.signInSuccess(token, data));
  },
  setSetSettings: (key, value) => {
    dispatch(utilsAct.setSetSettings(key, value));
  },
  signOutSuccess: () => {
    dispatch(authAct.signOutSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);