import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as authAct from '../../store/auth/_authActions';
import * as utilsAct from '../../store/data/_dataActions';


const Login = React.lazy(() => import('./Login'));
const Reset = React.lazy(() => import('./Reset'));
const Signup = React.lazy(() => import('./Signup'));

const routes = [
    { path: '/', component: Login, exact: true },
    { path: '/signup', component: Signup, exact: true },
    { path: '/reset', component: Reset, exact: true },
    { path: '/reset/:code', component: Reset, exact: true },
];

const Authenticate = (props) => {

    return (
        <React.Fragment>
            <div className="bg-gradient-primary">
                <div className="container d-flexs justify-content-centers align-items-centers" style={{ height: '100vh' }}>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-12 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-4">

                                    <div className="text-center">
                                        <img src="/assets/img/logo.png" alt="FayaSMS WhiteLabel" style={{ height: 40 }} />
                                    </div>

                                    <Switch>
                                        {routes.map((row) => (
                                            <Route key={row.path} path={row.path} exact={row.exact} render={(p) => <row.component {...props} {...p} />} />
                                        ))}

                                        <Redirect to={routes[0].path} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    _auth: state.auth,
    _data: state.data,
    _router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
    signInSuccess: (token, data) => {
        dispatch(authAct.signInSuccess(token, data));
    },
    setSetSettings: (key, value) => {
        dispatch(utilsAct.setSetSettings(key, value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);