import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/_store';
import { Loading } from './components';

import * as authAct from './store/auth/_authActions';
import * as utilsAct from './store/utils/_utilsActions';
import * as dataAct from './store/data/_dataActions';

// ::: partials
const Footer = React.lazy(() => import('./partials/Footer'));
const Header = React.lazy(() => import('./partials/Header'));
const Navigation = React.lazy(() => import('./partials/Navigation'));

// ::: screens
const KYC = React.lazy(() => import('./screens/KYC'));
const User = React.lazy(() => import('./screens/User'));
const Reports = React.lazy(() => import('./screens/Reports'));
const Senders = React.lazy(() => import('./screens/Senders'));
const Contacts = React.lazy(() => import('./screens/Contacts'));
const Messages = React.lazy(() => import('./screens/Messages'));
const BuyCredit = React.lazy(() => import('./screens/BuyCredit'));
const Dashboard = React.lazy(() => import('./screens/Dashboard'));
const Templates = React.lazy(() => import('./screens/Templates'));
const Developers = React.lazy(() => import('./screens/Developers'));
const WhiteLabel = React.lazy(() => import('./screens/WhiteLabel'));

const Main = (props) => {
  const { _auth: { logg }, _data: { whiteLabel } } = props;

  const role = whiteLabel?.user?.uuid === logg.uuid ? 'white' : 'user';
  const routes = [
    { path: '/', component: Dashboard, exact: true, roles: ['user', 'white'], },
    { path: '/user', name: 'User', component: User, roles: ['user', 'white'], },
    { path: '/senders', name: 'Senders', component: Senders, roles: ['user'], },
    { path: '/reports', name: 'Reports', component: Reports, roles: ['user'], },
    { path: '/messages', name: 'Messages', component: Messages, roles: ['user'], },
    { path: '/contacts', name: 'Contacts', component: Contacts, roles: ['user'], },
    { path: '/templates', component: Templates, roles: ['user'], },
    { path: '/buy-credits', name: 'Buy Credits', component: BuyCredit, roles: ['user', 'white'], },
    { path: '/developers', name: 'Developers', component: Developers, roles: ['user'], },
    { path: '/whitelabel', name: 'WhiteLabel', component: WhiteLabel, roles: ['white'], },
  ].filter((rt) => rt.roles.includes(role));

  const kyc = {
    tier1: (logg.preferences.kyc || {}).email && (logg.preferences.kyc || {}).phone ? true : false,
  }

  return (
    <React.Fragment>
      <ConnectedRouter history={history}>
        <div id="page-top">
          <div id="wrapper">
            {kyc.tier1 && (
              <Navigation {...props} role={role} />
            )}
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <Header {...props} role={role} />
                <div className="container-fluid" style={{ height: 'calc(100vh - 170px)' }}>
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    {/* <h1 className="h3 mb-0 text-gray-800">{pageTitle}</h1> */}
                    {/* <span className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm pointer"><i
                                                className="fas fa-download fa-sm text-white-50"></i> Generate Report</span> */}
                  </div>
                  <React.Suspense fallback={<Loading role={role} />}>
                    {!kyc.tier1 && (
                      <KYC {...props} role={role} />
                    )}
                    {kyc.tier1 && (
                      <Switch>
                        {routes.map((row) => (
                          <Route
                            key={row.path}
                            path={row.path}
                            exact={row.exact}
                            render={(p) => <row.component {...props} {...p} role={role} />}
                          />
                        ))}

                        <Redirect to={routes[0].path} />
                      </Switch>
                    )}
                  </React.Suspense>
                </div>
              </div>

              <Footer {...props} />
            </div>
          </div>
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </div>
      </ConnectedRouter>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  _auth: state.auth,
  _data: state.data,
  _utils: state.utils,
  _router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  signOutSuccess: () => {
    dispatch(authAct.signOutSuccess());
  },
  profileUpdated: (data) => {
    dispatch(authAct.profileUpdated(data));
  },
  setPageTitle: (title) => {
    dispatch(utilsAct.setPageTitle(title));
  },
  setPageSubTitle: (title) => {
    dispatch(utilsAct.setPageSubTitle(title));
  },
  setSetSettings: (key, value) => {
    dispatch(dataAct.setSetSettings(key, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
