import { Button } from 'antd';
import React from 'react';

const Empty = (props) => {

    return (
        <React.Fragment>
            <div style={{ height: '70vh', justifyContent: 'center', ...props.style }} className="flex-middle">
                <div className="text-center pd-50">
                    <img src={props.img} alt={props.text} style={{ height: 150 }} />
                    <div>&nbsp;</div>
                    <div dangerouslySetInnerHTML={{ __html: props.text }} />

                    {props.btnText && props.btnAction && (
                        <div>
                            <p>&nbsp;</p>
                            <Button type="primary" size="large" onClick={() => props.btnAction()}>
                                <span dangerouslySetInnerHTML={{ __html: props.btnText }} />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Empty;