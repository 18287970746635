import React from 'react';
import { func } from './utils';

const Country = (props) => {

    return (
        <React.Fragment>
            <div className="d-flex align-items-center pd-30" style={{ height: '100vh' }}>
                <div className="card" style={{ width: '100%' }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src="assets/img/icon.png" alt="FayaVotes" width="160px" className="mb-4" />
                                <h1><b>We are sorry</b>.</h1>
                                <p className="text-muted">FayaVotes is not yet available in your location.</p>
                                <p>We have detected you are connecting from {func.getStorageJson('geo').name}.</p>
                            </div>
                            {/* <div className="col-4 col-lg-4 flex-middle">
                                    <img src={require('./assets/img/world.svg')} alt="FayaVotes" className="img-fluid" />
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Country;