import { Button, Form, Input, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { axius } from '../utils';

const TransferMoney = (props) => {
    const { visible, _auth: { logg } } = props;

    const [form] = Form.useForm();

    const [step, setStep] = useState(1);
    const [user, setUser] = useState({});
    const [source, setSource] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [destination, setDestination] = useState('');

    const [submitting, setSubmitting] = useState(false);


    useEffect(() => {
        if (visible) {
            setStep(props.user.uuid ? 3 : 1);
            setUser(props.user);
            setSource(props.source);
            setDestination(props.destination);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const submit = () => {
        var validates = {
            1: ['user'],
            2: [],
            3: ['amount'],
        };
        form.validateFields(validates[step]).then(v => {
            switch (step) {
                default:
                    setStep(step + 1);
                    break;
                case 1:
                    setSubmitting(true);
                    setErrMessage('');
                    axius.get(`users/${v.user}`).then(res => {
                        setSubmitting(false);
                        if (res.status === 200) {
                            setUser(res.data);
                            setStep(step + 1);
                        } else {
                            setErrMessage(`No user was found with: ${v.user}`);
                        }
                    });
                    break;
                case 3:
                    setSubmitting(true);
                    v['user'] = user.uuid;
                    v['source'] = source;
                    v['destination'] = destination;
                    axius.put(`users/${logg.uuid}/transfers`, v).then(res => {
                        setSubmitting(false);
                        if (res.status === 200) {
                            close();
                            props.profileUpdated(res.sender);
                            message.success('Tranfer succesful');
                        } else {
                            setErrMessage(res.message);
                        }
                    });
                    break;

            }
        });
    }



    const close = () => {
        setStep(1);
        setUser({});
        form.resetFields();
        props.onCancel();
    }

    return (
        <React.Fragment>
            <Modal visible={visible} title={`Transfer ${source}`} footer={null} onCancel={close} width={500}>
                {errMessage && (
                    <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: errMessage }} />
                )}
                <Form form={form} layout="vertical">
                    <div className={step !== 1 ? 'hide' : ''}>
                        <Form.Item colon={false} label="Recipient email / username" name="user" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input autoComplete="off" size="large" autoFocus placeholder="" disabled={submitting} />
                        </Form.Item>
                    </div>

                    <div className={step !== 2 ? 'hide' : ''}>
                        <div className="text-center">
                            <img src={user.avatar_link} alt={user.name} className="pointer img-circle img-thumbnail" style={{ width: '20%' }} />
                        </div>
                        <div className="summary">
                            <div className="div">
                                <div className="float-left">Name:</div>
                                <div className="float-right">{user.name}</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="div">
                                <div className="float-left">Username:</div>
                                <div className="float-right">{user.username}</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="div">
                                <div className="float-left">Email address:</div>
                                <div className="float-right">{user.email}</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="div">
                                <div className="float-left">Phone no.:</div>
                                <div className="float-right">{user.phone}</div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>

                    <div className={step !== 3 ? 'hide' : ''}>
                        <Form.Item colon={false} label="Amount to transfer" name="amount" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input autoComplete="off" size="large" placeholder="" addonBefore={(user.country || {}).currency} disabled={submitting} />
                        </Form.Item>
                    </div>

                    <div className="mt-4">
                        <div className="float-left">
                            {step > 1 && (
                                <Button type="default" loading={submitting} onClick={() => setStep(step - 1)}>
                                    back
                                </Button>
                            )}
                        </div>
                        <div className="float-right">
                            <Button type="primary" loading={submitting} onClick={submit}>
                                {step === 3 ? 'Transfer' : 'Next'}
                            </Button>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
}

export default TransferMoney;