import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';

export const config = {
  env: 'local',
  dbpref: 'fsms_wl_',
  version: require('../../package.json').version,
  local: {
    url: 'http://localhost/fayasms/api/',
    web: 'http://localhost/fayasms/web/',
    apiKey: 'fXnIXhcVEbEt8PbXKy2dTcGcwJfIammBMMcwUPXEppJDxLIFqCj9lUTzGgslKwaD',
    platform: 'localhost',
  },
  dev: {
    url: 'https://qa-api.fayasms.com/',
    web: 'https://qa.fayasms.com/',
    apiKey: 'I4g9FSq8uDRVkwZmYSgRIeCAg791lMZ1lJgwX34nfqG53KlScUHxch1urC2MkrVN',
    platform: 'qa-wl',
  },
  live: {
    url: 'https://api.fayasms.com/v3/',
    web: 'https://fayasms.com/',
    apiKey: 'dh4lG3X2uceWG3ksEtAdbJeNUnBHJWteSbJ26gpRk65Is7LSSqa9MKgUFa1XvSU5',
    platform: 'wl',
  },
  apiToken: '',
  headers: {},
  headersFile: {},
};

export const app = {
  version: require("../../package.json").version,
};

export const initialize = () => {
  if (window.location.hostname === 'localhost') {
    config.env = 'local';
  } else if (window.location.host === 'qa-wl.fayasms.com') {
    config.env = 'dev';
  } else {
    config.env = 'live';
  }
  config.apiToken = getStorage('token');
  config.whiteLabel = 'wl_tzV5GWvWATd8zSRltUrYGSvjYmGpDKef';

  config.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Platform': `${config[config.env].platform}/${app.version}`,
    'faya-access-token': `${config[config.env].apiKey}.${config.apiToken}.${config.whiteLabel}`,
  };
  config.headersFile = {
    'Accept': 'application/json',
    'Platform': `${config[config.env].platform}/${app.version}`,
    'faya-access-token': `${config[config.env].apiKey}.${config.apiToken}.${config.whiteLabel}`,
  };
};

export const dates = {
  yr: moment().format('YYYY'),
};

// ::: storage
export const setStorage = (key, value) => {
  if (key && value) {
    localStorage.setItem(config.dbpref + key, value);
  }
};
export const getStorage = (key) => {
  const value = localStorage.getItem(config.dbpref + key);
  return value || '';
};
export const setStorageJson = (key, value) => {
  if (key && value) {
    localStorage.setItem(config.dbpref + key, JSON.stringify(value));
  }
};
export const getStorageJson = (key) => {
  if (key) {
    const value = localStorage.getItem(config.dbpref + key);
    return JSON.parse(value) || '';
  }
};
export const delStorage = (key) => {
  if (key) {
    localStorage.removeItem(config.dbpref + key);
  }
};

export const mergeObj = (obj, src) => {
  for (var key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key];
  }
  return obj;
};
export const getFileExtension = (filename) => {
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext === null ? '' : ext[1];
};

// Spinners
export const fspinner = (
  <div
    style={{
      textAlign: 'center',
      color: '#999',
      lineHeight: 320 + 'px',
      width: 100 + '%',
    }}
  >
    <i className='fa fa-spin fa-circle-o-notch fa-5x' />
  </div>
);
export const fspinner_sm = (
  <div
    style={{
      textAlign: 'center',
      color: '#999',
      lineHeight: 120 + 'px',
      width: 100 + '%',
    }}
  >
    <i className="fa fa-spin fa-circle-o-notch fa-3x" />
  </div>
);
export const fspinner_xs = <i className="fa fa-spin fa-spinner" />;

export const redirect = (to) => {
  window.location = to;
};

export const generateOptions = (length, step = 1) => {
  const arr = [];
  for (let value = 0; value < length; value += step) {
    arr.push(value);
  }
  return arr;
};

export const hasR = (role) => {
  return true;
  // let user = getStorageJson('user');
  // let myRoles = ((user.role || {}).data || '').split(',');
  // return (myRoles.includes(role) || myRoles.includes('*'));
};

export const numberFormat = (number, minimumFractionDigits = 0) => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(number);
};

export const loading = (content) => {
  return new Promise((resolve) => {
    content = (
      <div
        className="text-center"
        dangerouslySetInnerHTML={{
          __html: `<i class="fa fa-spin fa-spinner"></i> <span>${content}</span>`,
        }}
      />
    );
    const loading = Modal.info({
      icon: null,
      title: null,
      centered: true,
      content,
      width: "250px",
      cancelText: <div />,
      okButtonProps: { style: { display: "none" } },
      cancelButtonProps: { style: { display: "none" } },
    });
    resolve(loading);
  });
};

export const alert = (title, content, props) => {
  Modal.warning({
    icon: null,
    title,
    centered: true,
    content,
    okText: "Okay!",
    width: "300px",
    cancelText: <div />,
    ...props,
  });
};

export const randNum = (length = 6) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randCode = (length = 6) => {
  var result = "";
  var characters = "ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const serviceFee = (a = {}) => {
  return +a.revenue * (+a.preferences.norm_vote_fee / 100);
};

export const revenue = (a = {}) => {
  return +a.revenue - serviceFee(a);
};

export const datesBetween = (startDate, endDate, format = "YYYY-MM-DD") => {
  var dates = [];
  var currentDate = startDate;
  var addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    var date = moment(currentDate).format(format);
    if (!dates.includes(date)) {
      dates.push(date);
    }
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export const imgError = (e) => {
  e.target.src = "/assets/img/default.jpg";
};

export const smsCount = (e) => {
  return new Promise((resolve) => {
    if (e) {
      var part1 = 158;
      var part2 = 145;
      var part3 = 152;
      var pages = 0;
      // var remaining = 0;
      var chars = e.length;
      if (chars <= part1) {
        pages = 1;
        // remaining = part1 - chars;
      } else if (chars <= part1 + part2) {
        pages = 2;
        // remaining = (part1 + part2) - chars;
      } else if (chars > part1 + part2) {
        var moreM = Math.ceil((chars - part1 - part2) / part3);
        pages = 2 + moreM;
        // remaining = part1 + part2 + (moreM * part3) - chars;
      }
      resolve(`${chars} / ${pages} SMS`);
    } else {
      resolve("0 / 0 SMS");
    }
  });
};
