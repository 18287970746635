import axios from 'axios';
import { func } from '.';

const signOutSuccess = (path = '/') => {
    func.delStorage('token');
    func.delStorage('user');
    func.redirect(path);
};

export const apnData = (obj) => {
    const body = new FormData();
    for (var p in obj) {
        if (p === 'file') {
            body.append('file[0]', obj[p]);
        } else if (p === 'image') {
            body.append('image[0]', obj[p]);
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

export const apiHeaders = (type = '') => {
    func.initialize();
    if (type === 'file') {
        return func.config.headersFile;
    } else {
        return func.config.headers;
    }
}


export const post = async (action, data = {}) => {
    let headers = apiHeaders();
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? func.config[func.config.env].url + action : action);
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        let res = await response.json();
        if (res.status === 505 && func.getStorageJson('user').uuid) {
            return signOutSuccess();
        }
        return res;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const get = async (action, data = {}) => {
    let headers = apiHeaders();
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? func.config[func.config.env].url + action : action);
    return axios({
        method: 'GET', url,
        headers: empty ? {} : headers,
        params: data
    }).then(response => {
        const res = response.data;
        if (res.status === 505 && func.getStorageJson('user').uuid) {
            return signOutSuccess();
        }
        return res;
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const put = async (action, data = {}) => {
    let headers = apiHeaders();
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? func.config[func.config.env].url + action : action);
    try {
        let response = await fetch(url, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        let res = await response.json();
        if (res.status === 505 && func.getStorageJson('user').uuid) {
            return signOutSuccess();
        }
        return res;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const delte = async (action, data = {}) => {
    let headers = apiHeaders();
    try {
        let response = await fetch(func.config[func.config.env].url + action, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        let res = await response.json();
        if (res.status === 505 && func.getStorageJson('user').uuid) {
            return signOutSuccess();
        }
        return res;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const postFile = async (action, data = {}) => {
    let headers = apiHeaders('file');
    try {
        let response = await fetch(func.config[func.config.env].url + action, {
            method: 'POST',
            headers,
            body: apnData(data)
        });
        let res = await response.json();
        if (res.status === 505 && func.getStorageJson('user').uuid) {
            return signOutSuccess();
        }
        return res;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}