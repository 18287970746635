import React from 'react';

const Title = ({ title }) => {

    return (
        <React.Fragment>
            <div className="card mb-4">
                <div className="card-body p-3">
                    <b className="mb-0" dangerouslySetInnerHTML={{ __html: title }} style={{ fontSize: 16 }} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Title;