import * as func from '../../utils/functions';
import { SET_SET_SETTINGS } from '../_types';

const initialState = {
    settings: func.getStorageJson('settings') || {},
    countries: func.getStorageJson('countries') || [],
    whiteLabel: func.getStorageJson('whiteLabel') || {},

    contactFields: [
        { name: 'Phone number', code: 'NUMBER', },
        { name: 'First name', code: 'FIRST_NAME', },
        { name: 'Last name', code: 'LAST_NAME', },
        { name: 'Custom 1', code: 'CUSTOM1', },
        { name: 'Custom 2', code: 'CUSTOM2', },
        { name: 'Custom 3', code: 'CUSTOM3', },
        { name: 'Custom 4', code: 'CUSTOM4', },
    ],

    navigation: {
        user: [
            { code: '', name: 'Overview', icon: 'mdi mdi-apps', link: '', subs: [] },
            {
                code: 'sms', name: 'Messages', icon: 'mdi mdi-message', link: 'messages', subs: [
                    { code: 'sms_frm', name: 'Compose message', link: 'messages/form', rules: [] },
                    { code: 'sms_lst', name: 'Sent messages', link: 'messages/sent', rules: [], },
                    { code: 'sms_sch', name: 'Scheduled messages', link: 'messages/schedules', rules: [] },
                ],
            },
            { code: 'tmp', name: 'Templates', icon: 'mdi mdi-file-hidden', link: 'templates', subs: [] },
            { code: 'cnt', name: 'Contacts', icon: 'mdi mdi-account-multiple', link: 'contacts', subs: [] },
            { code: 'snd', name: 'Sender IDs', icon: 'mdi mdi-link', link: 'senders', subs: [] },
            {
                code: 'rep', name: 'Reports', icon: 'mdi mdi-chart-gantt', link: 'reports', subs: [
                    { code: 'rep_sms', name: 'Messages report', link: 'reports/messages', rules: [] },
                    { code: 'rep_pay', name: 'Payment report', link: 'reports/payments', rules: [], },
                    { code: 'rep_com', name: 'Commissions report', link: 'reports/commissions', rules: [] },
                ],
            },
            { code: 'crd', name: 'Buy Credits', icon: 'mdi mdi-cash-usd', link: 'buy-credits', subs: [] },
            { code: 'dev', name: 'Developers', icon: 'mdi mdi-code-tags', link: 'developers', subs: [] },
        ],
        white: [
            { code: '', name: 'Overview', icon: 'mdi mdi-apps', link: '', subs: [] },
            { code: 'crd', name: 'Buy Credits', icon: 'mdi mdi-cash-usd', link: 'buy-credits', subs: [] },
            { code: 'wlb_usr', name: 'Users', icon: 'mdi mdi-account-multiple', link: 'whitelabel/users', subs: [] },
            { code: 'wlb_com', name: 'Commissions', icon: 'mdi mdi-cash-usd', link: 'whitelabel/commissions', subs: [] },
            { code: 'wlb_frm', name: 'Edit label', icon: 'mdi mdi-application', link: 'whitelabel/form', subs: [] },
        ]
    },
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_SET_SETTINGS:
            return {
                ...state,
                [action.key]: action.value
            };
    }
};


export default dataReducer;